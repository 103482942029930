var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mentorias-main-container"},[_c('h1',[_vm._v("Mentorias - Panel de gestión")]),_c('div',{staticClass:"select-container"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.select_value),expression:"select_value"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.select_value=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.opciones),function(opcion){return _c('option',{key:opcion,domProps:{"value":opcion}},[_vm._v(_vm._s(opcion))])}),0)]),(_vm.select_value == 'Reservadas')?_c('div',{staticClass:"reservadas"},[_vm._l((_vm.book_tutorials),function(tutoria,index){return _c('div',{key:index,staticClass:"tutoria",style:({
        backgroundColor: tutoria.completada
          ? _vm.colorCompletado
          : _vm.colorPendiente,
      })},[_c('h2',[_vm._v(_vm._s("Mentoria con " + tutoria.teacher_name))]),_c('div',{staticClass:"params"},[_c('div',{staticClass:"params-child"},[_vm._v(_vm._s("Fecha: " + _vm.ordenarFecha(tutoria.time)))]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Duracion: " + tutoria.duration + " sesiones - " + tutoria.duration * 10 + " min")+" ")]),_c('div',{staticClass:"params-child"},[_vm._v("Estado: Reservada")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Tema a tratar: " + tutoria.description)+" ")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Nombre de alumno: " + tutoria.user.name)+" ")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Email de alumno: " + tutoria.user.email)+" ")])]),_c('div',{staticClass:"zoom-link-container"},[_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm._setTutorialAsCompleted(tutoria.tid, tutoria.zoom_uid)}}},[_vm._v(" Marcar como completada ")]),_c('button',{staticClass:"cancel-button",on:{"click":function($event){return _vm._openPopUp(index)}}},[_vm._v(" Cancelar ")]),_c('Tutorials_Cancelation',{attrs:{"window_open":_vm.cancelation_pop_up,"index":index,"tid":tutoria.tid,"uid":_vm._g_UserId,"mentoria_email":tutoria.user.email,"duration":tutoria.duration,"uuid":tutoria.uuid},on:{"window_close":_vm.window_close,"_getTutorials":_vm._getTutorials}}),_c('a',{attrs:{"href":tutoria.join_url,"target":"_blank"}},[_c('img',{staticClass:"zoom-image",attrs:{"src":require("@/views/assets/zoom-icon.png")}})])],1)])}),(_vm.book_tutorials.length == 0)?_c('div',{staticClass:"no-tutorials"},[_vm._v("No existen tutorias reservadas en este momento")]):_vm._e()],2):_c('div',[_vm._l((_vm.completed_tutorials),function(tutoria){return _c('div',{key:tutoria.id,staticClass:"tutoria",style:({
        backgroundColor: tutoria.completada
          ? _vm.colorCompletado
          : _vm.colorPendiente,
      })},[_c('h2',[_vm._v(_vm._s("Tutoria con " + tutoria.teacher_name))]),_c('div',{staticClass:"params"},[_c('div',{staticClass:"params-child"},[_vm._v(_vm._s("Fecha: " + _vm.ordenarFecha(tutoria.time)))]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Duracion: " + tutoria.duration + " sesiones")+" ")]),_c('div',{staticClass:"params-child"},[_vm._v("Estado: Completada")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Tema a tratar: " + tutoria.description)+" ")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Nombre de alumno: " + tutoria.user.name)+" ")]),_c('div',{staticClass:"params-child"},[_vm._v(" "+_vm._s("Email de alumno: " + tutoria.user.email)+" ")])])])}),(_vm.completed_tutorials.length == 0)?_c('div',{staticClass:"no-tutorials"},[_vm._v("No existen tutorias reservadas en este momento")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }