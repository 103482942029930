<template>
  <div class="mentorias-main-container">
    <h1>Mentorias - Panel de gestión</h1>
    <div class="select-container">
      <select v-model="select_value">
        <option v-for="opcion in opciones" :key="opcion" :value="opcion">{{
          opcion
        }}</option>
      </select>
    </div>

    <div v-if="select_value == 'Reservadas'" class="reservadas">
      <div
        v-for="(tutoria, index) in book_tutorials"
        :key="index"
        class="tutoria"
        :style="{
          backgroundColor: tutoria.completada
            ? colorCompletado
            : colorPendiente,
        }"
      >
        <h2>{{ "Mentoria con " + tutoria.teacher_name }}</h2>

        <div class="params">
          <div class="params-child">{{ "Fecha: " + ordenarFecha(tutoria.time) }}</div>
          <div class="params-child">
            {{ "Duracion: " + tutoria.duration + " sesiones  - " + tutoria.duration * 10 + " min" }}
          </div>
          <div class="params-child">Estado: Reservada</div>
          <div class="params-child">
            {{ "Tema a tratar: " + tutoria.description }}
          </div>
          <div class="params-child">
            {{ "Nombre de alumno: " + tutoria.user.name }}
          </div>
          <div class="params-child">
            {{ "Email de alumno: " + tutoria.user.email }}
          </div>
        </div>
        <div class="zoom-link-container">
          <button
            class="custom-button"
            @click="_setTutorialAsCompleted(tutoria.tid, tutoria.zoom_uid)"
          >
            Marcar como completada
          </button>
          <button class="cancel-button" @click="_openPopUp(index)">
            Cancelar
          </button>
          <Tutorials_Cancelation
                :window_open="cancelation_pop_up"
                :index="index"
                :tid="tutoria.tid"
                :uid="_g_UserId"
                :mentoria_email="tutoria.user.email"
                :duration="tutoria.duration"
                :uuid="tutoria.uuid"
                @window_close="window_close"
                @_getTutorials="_getTutorials"
              />
          <a :href="tutoria.join_url" target="_blank">
            <img class="zoom-image" src="@/views/assets/zoom-icon.png" />
          </a>
        </div>
      </div>
      <div v-if="book_tutorials.length == 0" class="no-tutorials">No existen tutorias reservadas en este momento</div>
    </div>
    <div v-else>
      <div
        v-for="tutoria in completed_tutorials"
        :key="tutoria.id"
        class="tutoria"
        :style="{
          backgroundColor: tutoria.completada
            ? colorCompletado
            : colorPendiente,
        }"
      >
        <h2>{{ "Tutoria con " + tutoria.teacher_name }}</h2>
        <div class="params">
          <div class="params-child">{{ "Fecha: " + ordenarFecha(tutoria.time) }}</div>
          <div class="params-child">
            {{ "Duracion: " + tutoria.duration + " sesiones" }}
          </div>
          <div class="params-child">Estado: Completada</div>
          <div class="params-child">
            {{ "Tema a tratar: " + tutoria.description }}
          </div>
          <div class="params-child">
            {{ "Nombre de alumno: " + tutoria.user.name }}
          </div>
          <div class="params-child">
            {{ "Email de alumno: " + tutoria.user.email }}
          </div>
        </div>
      </div>
      <div v-if="completed_tutorials.length == 0" class="no-tutorials">No existen tutorias reservadas en este momento</div>
    </div>
  </div>
</template>

<script>
import Tutorials_Cancelation from "@/views/Mentorias/Mentorias_popup_cancelation.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";

export default {
  name: "Admin_mentorias",
  mixins: [APICaller],
  components: {
    Tutorials_Cancelation,
  },
  data() {
    return {
      book_tutorials: [],
      completed_tutorials: [],
      colorCompletado: "#d0f0f7",
      colorPendiente: "#f7f7f7",
      opciones: ["Reservadas", "Completadas"],
      select_value: "Reservadas",
      cancelation_pop_up: -1,
    };
  },
  computed: {
    ...mapGetters(["_g_UserId", "_g_User"]),
  },
  async mounted() {
    await this._getTutorials();
  },
  methods: {
    _openPopUp(index) {
      this.cancelation_pop_up = index;
    },
    window_close() {
      this.cancelation_pop_up = -1;
    },
    _getTutorials: function() {
      this.book_tutorials = [];
      this.completed_tutorials = [];

      var uid = this._g_UserId;

      let success = (response) => {
        var that = this;
        response.data.forEach(function(element) {
          if (element.status == 1) {
            that.book_tutorials.push(element);
          }
          if (element.status == 2) {
            that.completed_tutorials.push(element);
          }
        });
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "333",
        "Error al recuperar las tutorias disponibles de usuario."
      );

      let url = "/api/v1/eurekers-tutorials/tutor/" + uid;

      this._getAPICall(url, successHandler, failureHandler);
    },

    ordenarFecha(fecha) {
      var [fechaParte, horaParte] = fecha.split("  ");
      var [mes, dia, anio] = fechaParte.split("/");
      var [hora, minutos, segundos] = horaParte.split(":");

      return `${dia}/${mes}/${anio} ${hora}:${minutos}`;
    },

    _setTutorialAsCompleted: function(tid, zoom_uid) {
      
      var uid = this._g_UserId;

      let params = new URLSearchParams();
      params.append("tid", tid);
      params.append("uid", uid);
      params.append("zoom_uid", zoom_uid);

      let success = (response) => {
        this._getTutorials();
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "390",
        "Error al marcar como completada la tutoria."
      );

      let url = "/api/v1/eurekers-tutorials/execute";

      this._postAPICall(url, params, successHandler, failureHandler);
    },
  },
};
</script>

<style scoped>
h1 {
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.tutoria {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  margin: 13px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.params {
  display: flex;
  flex-wrap: wrap;
}

.params-child {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
}

.tutoria h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.tutoria p {
  margin-bottom: 5px;
}

.select-container {
  display: flex;
  justify-content: end;
  margin-right: 25px;
}

select {
  border: none;
  background-color: #f2f2f2;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border-radius: 10px;
  float: right;
}

select:focus {
  outline: none;
  box-shadow: none;
}

option {
  background-color: #fff;
  color: #333;
}

select::-ms-expand {
  display: none;
}

select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: -30px;
  padding: 10px;
  background-color: #f2f2f2;
  color: #333;
}

.zoom-link-container {
  display: flex;
  flex: wrap;
  justify-content: end;
}

.zoom-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.custom-button {
  background-color: #3cb7e3;
  border: none;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin-right: 10px;
}

.custom-button:hover {
  background-color: #2283a6;
  cursor: pointer;
}

.no-tutorials{
  text-align: center;
  margin-top: 33px;
  font-size: 18px;
}

.cancel-button{
  background-color: #e33c3c;
  border: none;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin-right: 10px;
  cursor: pointer;
}


.reservadas{
  padding-bottom: 100px;
}

</style>
